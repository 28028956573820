import React, { useEffect, useState } from "react";
import BackNextButtons from "../../Components/BackNextButtons/BackNextButtons";
import FirstStepContainer from "./1. Step/FirstStepContainer";
import SecondStepContainer from "./2. Step/SecondStepContainer";
import ThirdStepContainer from "./3. Step/ThirdStepContainer";
import FourthStepContainer from "./4. Step/FourthStepContainer";
import { StoreType } from "../../redux/store/store";
import { useDispatch, useSelector } from "react-redux";
import {
   resetClaimModel,
   setClaimModel,
   updateFifthStepFields,
   updateFifthStepFinishFields,
} from "../../redux/reducers/claimReducer";
import useFetch from "../../CustomHooks/useFetch";
import { setVisibilityMatrixes } from "../../redux/reducers/visibilityMatrixesReducer";
import {
   resetClaimFieldErrors,
   setClaimFieldErrors,
} from "../../redux/reducers/claimFieldErrorsReducer";
import { useLocation } from "react-router-dom";
import {
   resetAgentErrors,
   setAgentErrors,
} from "../../redux/reducers/authReducer";
import SMSModal from "../../Components/Modals/SMSModal";
import FifthStepContainer from "./5. Step/FifthStepContainer";
import DisplayCurrentClaims from "../../Components/DisplayCurrentClaims/DisplayCurrentClaims";
import { Box } from "@mui/material";
import UploadFilesModal from "../../Components/Modals/UploadFilesModal";
import FinishModal from "../../Components/Modals/FinishModal";
import CustomStepper from "../../Components/CustomStepper/CustomStepper";
import QRSignatureModal from "../../Components/Modals/QRSignatureModal";

export type visibilityField = {
   propertyId: string;
   isVisible: boolean;
};

type visibilityMatrix = {
   typeOfClaimName: string;
   classFields: visibilityField[];
};

export type visibilityMatrixes = visibilityMatrix[];

const ClaimFormPage: React.FC = () => {
   const actualStepForUI = useSelector(
      (state: StoreType) => state.claimState.actualStepNumber
   );
   const dispatch = useDispatch();
   const [isSMSModalOpen, setIsSMSModalOpen] = useState(false);
   const [isUploadFilesModalOpen, setIsUploadFilesModalOpen] = useState(false);
   const [errorMessage, setErrorMessage] = useState("");
   const [isFinishModalOpen, setIsFinishModalOpen] = useState(false);
   const [isQRSignatureModalOpen, setIsQRSignatureModalOpen] = useState(false);

   const [completedStep, setCompletedStep] = useState(0);

   const isAuthorized = useLocation().pathname === "/claimReport/authorized";

   const claimModel = useSelector((state: StoreType) => state.claimState);

   const token = useSelector((state: StoreType) => state.tokenState.token);
   const uploadedFiles = useSelector((state: StoreType) => state.filesState);
   const isFinishedBy2DSignature = useSelector(
      (state: StoreType) =>
         state.claimState.reportModel.finishedClaimBy2DSignature
   );
   const isFinishedBySMS = useSelector(
      (state: StoreType) =>
         state.claimState.reportModel.finishedClaimByMobileIdentification
   );
   const isFinishedByUploadDocumentOnline = useSelector(
      (state: StoreType) =>
         state.claimState.reportModel.finishedClaimBySendDocumentOnline
   );
   const claimId = useSelector((state: StoreType) => state.claimState.id);
   const selectedClaims = useSelector(
      (state: StoreType) => state.claimState.selectedTypeOfClaims
   );

   const {
      sendRequest: sendRequestCreateClaim,
      data: dataCreateClaim,
      loading: loadingCreateAnonymClaim,
      error: errorCreateAnonymClaim,
      resetFetch: resetFetchCreateAnonymClaim,
      fetchFired: fetchFiredCreateAnonymClaim,
   } = useFetch();

   const callCreateClaim = (
      stepNumber: number,
      overRideNextStep?: boolean,
      agentId?: number
   ) => {
      let isStepNext;
      if (overRideNextStep) {
         isStepNext = true;
      } else {
         isStepNext = stepNumber > claimModel.actualStepNumber;
      }
      const correctURL =
         process.env.REACT_APP_BaseURL! +
         (isAuthorized
            ? process.env.REACT_APP_CreateClaimForClient!
            : process.env.REACT_APP_CreateClaimForAnonymousClient!);

      const correctHeaders = isAuthorized
         ? {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
           }
         : {
              "Content-Type": "application/json",
           };

      sendRequestCreateClaim({
         method: "POST",
         url: correctURL,
         headers: correctHeaders,
         data: {
            ...claimModel,
            actualStepNumber: stepNumber,
            command: isStepNext ? 0 : 1,
            reportModel: {
               ...claimModel.reportModel,
               agentModel: {
                  ...claimModel.reportModel.agentModel,
                  agentId: agentId,
               },
            },
         },
      });
      dispatch(
         updateFifthStepFields({
            field: "claimReportRowKey",
            value: "",
         })
      );
   };

   useEffect(() => {
      dispatch(resetClaimModel());
      callCreateClaim(actualStepForUI, true);
   }, []);

   useEffect(() => {
      if (errorCreateAnonymClaim) {
         if (errorCreateAnonymClaim.response.status !== 422) {
         } else {
            dispatch(
               setClaimFieldErrors(
                  errorCreateAnonymClaim.response.data.result.errorMessages
               )
            );
         }
      } else if (dataCreateClaim) {
         if (dataCreateClaim === true) {
            setIsFinishModalOpen(true);
         } else if (isFinishedBySMS && claimModel.actualStepNumber === 4) {
            setIsSMSModalOpen(true);
         } else if (
            isFinishedBy2DSignature &&
            claimModel.actualStepNumber === 4
         ) {
            setIsQRSignatureModalOpen(true);
         } else if (
            isFinishedByUploadDocumentOnline &&
            claimModel.actualStepNumber === 4
         ) {
            setIsUploadFilesModalOpen(true);
         } else {
            dispatch(setClaimModel({ ...dataCreateClaim.result }));
            dispatch(
               setVisibilityMatrixes({
                  ...dataCreateClaim.result.visibilityStepMatrixes,
               })
            );
            dispatch(resetClaimFieldErrors());
            window.scrollTo({
               top: 0,
               behavior: "smooth",
            });
         }
      }
   }, [fetchFiredCreateAnonymClaim]);

   useEffect(() => {
      if (completedStep <= claimModel.actualStepNumber) {
         setCompletedStep(claimModel.actualStepNumber);
      }
   }, [claimModel.actualStepNumber]);

   const {
      sendRequest: sendRequestUploadFiles,
      data: dataUploadFiles,
      loading: loadingUploadFiles,
      error: errorUploadFiles,
      resetFetch: resetFetchUploadFiles,
      fetchFired: fetchFiredUploadFiles,
   } = useFetch();

   const uploadFiles = () => {
      const finalFiles = new FormData();

      uploadedFiles.forEach((fileObj) => {
         finalFiles.append("files", fileObj.fileEntry.file, fileObj.fileName);
      });

      finalFiles.append("claimId", claimId as string);

      sendRequestUploadFiles({
         method: "POST",
         url:
            process.env.REACT_APP_BaseURL! + process.env.REACT_APP_UploadFiles,
         headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
         },
         data: finalFiles,
      });
   };

   useEffect(() => {
      if (errorUploadFiles) {
         setErrorMessage(
            "Nahrávání souboru proběhlo neúspěšně, zkontrolujte prosím, zdali posíláte koncovku souboru, kterou podporujeme (.png, .jpg, .jpeg, .bmp, .pdf, .heic, .heif, .tiff, .tif)."
         );
         return;
      }
      if (dataUploadFiles) {
         setErrorMessage("");
         callCreateClaim(actualStepForUI + 1);

         window.scrollTo({
            top: 0,
            behavior: "smooth",
         });
      }
   }, [fetchFiredUploadFiles]);

   const {
      sendRequest: sendRequestAgentSearch,
      data: dataAgentSearch,
      error: errorAgentSearch,
      resetFetch: resetFetchAgentSearch,
      fetchFired: fetchFiredAgentSearch,
   } = useFetch();

   const callAgentSearch = () => {
      sendRequestAgentSearch({
         method: "POST",
         url: process.env.REACT_APP_AgentSearch!,
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
         },
         data: {
            firstName: claimModel.reportModel.agentModel.agentFirstname,
            lastName: claimModel.reportModel.agentModel.agentLastname,
            phoneNumber: claimModel.reportModel.agentModel.agentPhoneNumber,
         },
      });
   };

   const { agentFirstname } = useSelector(
      (state: StoreType) => state.claimState.reportModel.agentModel
   );
   const { agentLastname } = useSelector(
      (state: StoreType) => state.claimState.reportModel.agentModel
   );
   const { agentPhoneNumber } = useSelector(
      (state: StoreType) => state.claimState.reportModel.agentModel
   );

   const agentClientSideErrorsHandler = () => {
      dispatch(resetAgentErrors());
      let firstnameError = "";
      let lastnameError = "";
      let numberError = "";

      if (!agentFirstname || agentFirstname.length < 2) {
         firstnameError = "Jméno poradce musí obsahovat alespoň 2 znaky.";
      }

      if (!agentLastname || agentLastname.length < 2) {
         lastnameError = "Příjmení poradce musí obsahovat alespoň 2 znaky.";
      }

      if (!agentPhoneNumber || agentPhoneNumber.length < 9) {
         numberError =
            "Telefonní číslo poradce musí obsahovat alespoň 9 znaků.";
      }

      dispatch(
         setAgentErrors({
            agentFirstname: firstnameError,
            agentLastname: lastnameError,
            agentPhoneNumber: numberError,
         })
      );
   };

   useEffect(() => {
      dispatch(resetAgentErrors());
      if (
         !agentFirstname ||
         agentFirstname.length < 2 ||
         !agentLastname ||
         agentLastname.length < 2 ||
         !agentPhoneNumber ||
         agentPhoneNumber.length < 9
      ) {
         agentClientSideErrorsHandler();
      } else if (dataAgentSearch) {
         if (dataAgentSearch?.length !== 0) {
            callCreateClaim(
               actualStepForUI + 1,
               false,
               dataAgentSearch[0].agentId
            );
            return;
         } else {
            callCreateClaim(actualStepForUI + 1, false);
            return;
         }
      } else {
         callCreateClaim(actualStepForUI + 1, false);
      }
   }, [
      fetchFiredAgentSearch,
      dataCreateClaim?.reportModel?.agentModel?.agentId,
   ]);

   const handleStepChange = (newStep: number) => {
      if (newStep > claimModel.actualStepNumber) {
         if (newStep === 5 && claimModel.reportModel.gdprAgreement) {
            dispatch(resetAgentErrors());
            resetFetchAgentSearch();
            callAgentSearch();
         } else if (newStep === 4) {
            resetFetchAgentSearch();
            dispatch(resetAgentErrors());

            if (uploadedFiles.length === 0) {
               setErrorMessage("");
               callCreateClaim(newStep);
               window.scrollTo({
                  top: 0,
                  behavior: "smooth",
               });
               return;
            }
            uploadFiles();
         } else if (newStep < 6) {
            setErrorMessage("");
            callCreateClaim(newStep);
         }
      } else {
         dispatch(
            updateFifthStepFinishFields({
               value: undefined,
            })
         );
         callCreateClaim(newStep);
         window.scrollTo({
            top: 0,
            behavior: "smooth",
         });
      }
   };

   const renderCurrentStep = (dataCreateClaim: any) => {
      if (!dataCreateClaim) return null;
      switch (actualStepForUI) {
         case 0:
            return <FirstStepContainer />;
         case 1:
            return <SecondStepContainer />;
         case 2:
            return <ThirdStepContainer />;
         case 3:
            return <FourthStepContainer errorMessage={errorMessage} />;
         case 4:
            return <FifthStepContainer />;
         default:
            return;
      }
   };

   return (
      <Box
         sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
         }}
      >
         <DisplayCurrentClaims claims={selectedClaims} />
         <CustomStepper
            completedStep={completedStep}
            activeStep={actualStepForUI}
            setActiveStep={callCreateClaim}
         />
         {renderCurrentStep(dataCreateClaim)}
         <BackNextButtons
            previousStepHandler={() => {
               handleStepChange(actualStepForUI - 1);
            }}
            nextStepHandler={() => {
               handleStepChange(actualStepForUI + 1);
            }}
            currentStep={actualStepForUI}
            dataCreateClaim={dataCreateClaim}
         />
         <SMSModal
            isModalOpen={isSMSModalOpen}
            setIsModalOpen={setIsSMSModalOpen}
            dataCreateClaim={dataCreateClaim}
            openFinishModal={() => setIsFinishModalOpen(true)}
            callCreateClaim={callCreateClaim}
         />
         {isUploadFilesModalOpen && (
            <UploadFilesModal
               isModalOpen={isUploadFilesModalOpen}
               setIsModalOpen={setIsUploadFilesModalOpen}
               openFinishModal={() => setIsFinishModalOpen(true)}
               dataCreateClaim={dataCreateClaim}
               callCreateClaim={callCreateClaim}
            />
         )}
         <FinishModal isModalOpen={isFinishModalOpen} />
         <QRSignatureModal
            isModalOpen={isQRSignatureModalOpen}
            closeModal={() => setIsQRSignatureModalOpen(false)}
            dataCreateClaim={dataCreateClaim}
            callCreateClaim={callCreateClaim}
            openFinishModal={() => setIsFinishModalOpen(true)}
         />
      </Box>
   );
};

export default ClaimFormPage;
