import React from "react";
import WholeLineContainer from "../../../../../../../Components/ReportFormTemplate/WholeLineContainer";
import CustomRadioGroup from "../../../../../../../Components/CustomInputs/CustomRadioGroup";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../../../../redux/store/store";
import { shouldRenderSpecField } from "../../../../../../../redux/reducers/visibilityMatrixesReducer";
import { handleShowErrorMessage } from "../../../../../../../redux/reducers/claimFieldErrorsReducer";
import { updateFirstStepGeneralFields } from "../../../../../../../redux/reducers/claimReducer";
import { useScrollToTopErrorInput } from "../../../../../../../CustomHooks/useScrollToTopErrorInput";

const IsInjuryFromAbroadField: React.FC = () => {
	const fieldNameLowerCase = "isInjuryFromAbroad";
	const fieldNameUpperCase =
		fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);
	const dispatch = useDispatch();

	const fieldValue = useSelector(
		(state: StoreType) =>
			state.claimState.injuryDescriptionModelBase[fieldNameLowerCase]
	);

	const visibilityMatrixes = useSelector(
		(state: StoreType) => state.visibilityMatrixesState
	);

	const claimFieldErrorsMessages = useSelector(
		(state: StoreType) => state.claimFieldErrorsState
	);

	//const { isChild, isThirdPerson } = useSelector(
	//   (state: StoreType) => state.claimState.reportModel
	//);

	const ref = React.useRef<HTMLDivElement>(null);
	useScrollToTopErrorInput(ref, fieldNameUpperCase);
	return (
		<WholeLineContainer
			dependencyValue={shouldRenderSpecField(
				"GeneralFields",
				fieldNameUpperCase,
				visibilityMatrixes
			)}
		>
			<CustomRadioGroup
				heading="Nastala událost v zahraničí?"
				firstLabel="Ano"
				secondLabel="Ne"
				value={fieldValue}
				updateFunc={(e: any) => {
					dispatch(
						updateFirstStepGeneralFields({
							value: e === "true" ? true : false,
							field: fieldNameLowerCase,
						})
					);
				}}
				validationText={handleShowErrorMessage(
					fieldNameUpperCase,
					claimFieldErrorsMessages
				)}
				inputRef={ref}
			/>
		</WholeLineContainer>
	);
};

export default IsInjuryFromAbroadField;
