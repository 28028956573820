import { createSlice } from "@reduxjs/toolkit";
import {
	NeededDocumentsInnerText,
	claimsType,
	documentName,
} from "../../Config/types/types";

type InjurySpecificTypeDetail = {
	typeOfClaimEnum: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
	dateOfClaimFrom: Date | null;
	dateOfClaimTo: Date | null;
	timeOfClaim: string | null;
	additionalInjuryDescription: string | null;
};

type FamilyMember = {
	personalId: string | null;
	firstName: string | null;
	lastname: string | null;
	dateOfBirth: string | null;
	injuredClientType: number;
};

export type EnterprenuerModel = {
	businessDescription: string | null;
	businessFromDate: Date | null;
};

export type EmployeeModel = {
	profession: string | null;
	employerNameAndAddress: string | null;
	employeeFrom: Date | null;
	employeeTo: Date | null;
};

export type VisibilityMatrix = {
	propertyId: documentName;
	isVisible: true;
};

export type VisibilityOfDocumentsFromClientMatrix = {
	typeOfClaimName: NeededDocumentsInnerText;
	classFields: VisibilityMatrix[];
};

export type RequiredDocumentsFromClient = {
	id: string;
	name: string;
};

export type uploadedFile = {
	typeOfClaimEnum: string;
	files: File[];
};

type PersonThroutfulModel = {
	insuredRelationship: 0 | 1 | 2 | 3 | null;
	throutfulPersonAddress: string | null;
	throutfulPersonPostCode: string | null;
	throutfulPersonCity: string | null;
	throutfulPersonIsContactAddressDifferent: boolean | null;
	throutfulPersonContactAddress: string | null;
	throutfulPersonContactCity: string | null;
	throutfulPersonContactPostCode: string | null;
	throutfulPersonDateOfBirth: Date | null;
	throutfulPersonCitizenship: string | null;
	throutfulPersonIsForeigner: boolean | null;
	throutfulPersonEmail: string | null;
	throutfulPersonChildFirstname: string | null;
	throutfulPersonChildLastname: string | null;
	throutfulPersonChildPersonalId: string | null;
	throutfulPersonFirstname: string | null;
	throutfulPersonLastname: string | null;
	throutfulPersonPersonalId: string | null;
	throutfulPersonPhoneNumber: string | null;
	throutfulPersonPlaceOfBirth: string | null;
	throutfulPersonIsMinor: boolean | null;
};

export type PersonModel = {
	contractId: string | null;
	firstname: string | null;
	lastname: string | null;
	placeOfBirth: string | null;
	citizenship: string | null;
	personalId: string | null;
	stateOfTaxResidance: string | null;
	codeOfHealthInsurance: string | null;
	isAnotherInsurance: boolean | null;
	nameOfAnotherInsuriens: string | null;
	address: string | null;
	city: string | null;
	postCode: string | null;
	isContactAddressDifferent: boolean | null;
	contactAddress: string | null;
	contactCity: string | null;
	contactPostCode: string | null;
	phoneNumber: string | null;
	email: string | null;
	isForeigner: boolean | null;
	kifo: string | null;
	isEmployed: boolean | null;
	unemployedFrom: Date | null;
	isHousewife: boolean | null;
	isPensioner: boolean | null;
	isStudent: boolean | null;
	otherJob: string | null;
	isEnterPrenuer: boolean | null;
	haveProblemHealthForHardWorkingInCapacity: boolean | null;
	isWorkedDuringIncapacityFrom: Date | null;
	isWorkedDuringIncapacityTo: Date | null;
	enterprenuerModel: EnterprenuerModel;
	employeeModel: EmployeeModel;
	familyMembers: FamilyMember[];
	personThroutful: PersonThroutfulModel;
	isOtherJob: boolean | null;
	responsibilityPersonForChildName: string | null;
	responsibilityPersonForChildLastname: string | null;
	responsibilityPersonForChildPersonId: string | null;
};

type PaymentModel = {
	isPayByBankAccount: boolean | null;
	needAdvancePayment: boolean | null;
	variableNumber: string | null;
	bankAccountNumber: string | null;
	paymentAddress: string | null;
	isPayToAnotherBankAccount: boolean | null;
	bankCode: string | null;
	prefixBankAccountNumber: string | null;
};

export type InjuryDescriptionModel = {
	injuryPlace: string;
	injuryDescription: string | null;
	injuryBodyPart: string | null;
	injuryBodyPartInjuredPreviously: boolean | null;
	hasHealthProblemsBefore: boolean | null;
	hasProblemsChildOrYouBeforeDesc: string | null;
	injuryBodyPartInjuredPreviouslyDescription: string | null;
	yourActivityJobDuringInjury: string | null;
	injuryDuringSport: boolean | null;
	typeOfSport: string | null;
	haveSportClubRegistration: boolean | null;
	dateOfSportRegistration: Date | null;
	contactToSportRegistration: string | null;
	isInjuryOccuriedDueJob: boolean | null;
	isDeadDueInjury: boolean | null;
	isInjuryDueCarAccident: boolean | null;
	involvedAccidentTypeEnum: number | null;
	isIntoxicatedOrInfluencedOfAlcohol: boolean | null;
	policeAddress: string | null;
	isContactedToPolice: boolean | null;
	isInjuryFromAbroad: boolean | null;
	isWorkInjury: boolean | null;
	isInvalidClientOrChildBeforeInjury: boolean | null;
	invalidClientOrChildBeforeInjuryFrom: Date | null;
	invalidClientOrChildBeforeDiagnose: string | null;
	needAccountElapsedExternalWaitingTime: boolean | null;
	dateOfFirstTreatment: Date | null;
	timeOfFirstTreatment: string | null;
	firstDoctorOrAmbulanceName: string | null;
	continuedTreatmentDoctorOrAmbulanceName: string | null;
	personalDoctorOrAmbulanceName: string | null;
	notaryNameAndAddressHandleEstate: string | null;
	injuryDescriptionModel: InjurySpecificTypeDetail[];
	typeOfInjuriesForSeriouseDieses: null | number;
	otherDiagnoseText: string;
};

export type finishMethodsType =
	| "mobileIdentification"
	| "senddocumentonline"
	| "offlinesignature"
	| "signature2d"
	| undefined;

type ReportModel = {
	finishedClaimByMobileIdentification: boolean | null;
	finishedClaimBySendDocumentOnline: boolean | null;
	finishedClaimBySendPostAdress: boolean | null;
	finishedClaimBy2DSignature: boolean | null;
	notice: string | null;
	reportingMessage: string;
	authorizationMobile: string;
	authorizationContractId: string;
	isSMSSend: boolean | null;
	smsCode: string;
	smsTransactionId: string;
	reportingCommand: number;
	reportType: number;
	isUploaded: boolean;
	uploadedFiles: uploadedFile[];
	documentsToDownloads: RequiredDocumentsFromClient[];
	isExposedPerson: boolean | null;
	isTaxResindentOfAnotherCountry: boolean | null;
	isHolderOfUSGreenCard: boolean | null;
	countryOfTaxResidence: string | null;
	countryOfTaxResidenceIdNumber: string | null;
	gdprAgreement: boolean | null;
	isMinor: boolean | null;
	claimFinishMethods: finishMethodsType[];
	claimReportRowKey: string | null;
	signModeAnonym: boolean;
	agentModel: {
		agentId: string | null;
		agentFirstname: string | null;
		agentLastname: string | null;
		agentPhoneNumber: string | null;
	};
	isChild: boolean;
	isThirdPerson: boolean;
};

type DocumentsUploadModel = {
	declarationOfOutOfWorkDuringPN: boolean | null;
	visibilityOfDocumentsFromClientMatrix: VisibilityOfDocumentsFromClientMatrix[];
};

export type ClaimType = {
	id: string | null;
	reportDate: string | null;
	selectedTypeOfClaims: claimsType[];
	loginInfo: any | null;
	command: number;
	actualStepNumber: number;
	clientIdentification: {
		personalId: string;
		contractId: string;
	};
	claimForPersonId: string;
	countOfSteps: number;
	isDownloadPending: boolean | null;
	isValid: boolean | null;
	errorMessages: string[];
	visibilityStepMatrixes: any[];
	reportModel: ReportModel;
	paymentModel: PaymentModel;
	personModel: PersonModel;
	injuryDescriptionModelBase: InjuryDescriptionModel;
	documentsUploadModel: DocumentsUploadModel;
};
export const initialState: ClaimType = {
	id: null,
	reportDate: new Date().toISOString(),
	selectedTypeOfClaims: [],
	loginInfo: "",
	command: 0,
	actualStepNumber: 0,
	clientIdentification: {
		personalId: "",
		contractId: "",
	},
	claimForPersonId: "",
	countOfSteps: 0,
	isDownloadPending: true,
	isValid: true,
	errorMessages: [],
	visibilityStepMatrixes: [],
	reportModel: {
		finishedClaimByMobileIdentification: false,
		finishedClaimBySendDocumentOnline: false,
		finishedClaimBySendPostAdress: false,
		finishedClaimBy2DSignature: false,
		signModeAnonym: false,
		notice: "",
		reportingMessage: "",
		authorizationMobile: "",
		authorizationContractId: "",
		isSMSSend: true,
		smsCode: "",
		smsTransactionId: "",
		reportingCommand: 0,
		reportType: 1,
		isUploaded: true,
		uploadedFiles: [],
		documentsToDownloads: [],
		isExposedPerson: null,
		isTaxResindentOfAnotherCountry: false,
		isHolderOfUSGreenCard: false,
		countryOfTaxResidence: "",
		countryOfTaxResidenceIdNumber: "",
		gdprAgreement: false,
		isMinor: false,
		claimFinishMethods: [],
		claimReportRowKey: "",
		agentModel: {
			agentId: "",
			agentFirstname: "",
			agentLastname: "",
			agentPhoneNumber: "",
		},
		isChild: false,
		isThirdPerson: false,
	},
	documentsUploadModel: {
		declarationOfOutOfWorkDuringPN: false,
		visibilityOfDocumentsFromClientMatrix: [],
	},
	paymentModel: {
		isPayByBankAccount: true,
		needAdvancePayment: null,
		isPayToAnotherBankAccount: null,
		variableNumber: "",
		bankAccountNumber: "",
		bankCode: "",
		prefixBankAccountNumber: "",
		paymentAddress: "",
	},
	personModel: {
		contractId: "",
		firstname: "",
		lastname: "",
		placeOfBirth: "",
		isForeigner: null,
		citizenship: "",
		personalId: "",
		stateOfTaxResidance: "",
		codeOfHealthInsurance: "",
		isAnotherInsurance: null,
		nameOfAnotherInsuriens: "",
		address: "",
		city: "",
		postCode: "",
		isContactAddressDifferent: false,
		contactAddress: "",
		contactCity: "",
		contactPostCode: "",
		phoneNumber: "",
		email: null,
		kifo: "",
		isEmployed: null,
		unemployedFrom: null,
		isHousewife: false,
		isPensioner: false,
		isStudent: null,
		otherJob: "",
		isEnterPrenuer: null,
		haveProblemHealthForHardWorkingInCapacity: null,
		isWorkedDuringIncapacityFrom: null,
		isWorkedDuringIncapacityTo: null,
		isOtherJob: null,
		responsibilityPersonForChildName: null,
		responsibilityPersonForChildLastname: null,
		responsibilityPersonForChildPersonId: null,
		enterprenuerModel: {
			businessDescription: "",
			businessFromDate: null,
		},
		employeeModel: {
			profession: "",
			employerNameAndAddress: "",
			employeeFrom: null,
			employeeTo: null,
		},
		familyMembers: [
			{
				personalId: "",
				firstName: "",
				lastname: "",
				dateOfBirth: null,
				injuredClientType: 0,
			},
		],
		personThroutful: {
			insuredRelationship: null,
			throutfulPersonAddress: "",
			throutfulPersonPostCode: "",
			throutfulPersonCity: "",
			throutfulPersonIsContactAddressDifferent: false,
			throutfulPersonContactAddress: "",
			throutfulPersonContactCity: "",
			throutfulPersonContactPostCode: "",
			throutfulPersonDateOfBirth: null,
			throutfulPersonEmail: "",
			throutfulPersonChildFirstname: "",
			throutfulPersonChildLastname: "",
			throutfulPersonChildPersonalId: "",
			throutfulPersonFirstname: "",
			throutfulPersonLastname: "",
			throutfulPersonIsForeigner: false,
			throutfulPersonCitizenship: "",
			throutfulPersonPersonalId: "",
			throutfulPersonPhoneNumber: "",
			throutfulPersonPlaceOfBirth: "",
			throutfulPersonIsMinor: false,
		},
	},
	injuryDescriptionModelBase: {
		isInjuryFromAbroad: null,
		injuryPlace: "",
		injuryDescription: "",
		injuryBodyPart: "",
		injuryBodyPartInjuredPreviously: null,
		hasHealthProblemsBefore: null,
		hasProblemsChildOrYouBeforeDesc: "",
		injuryBodyPartInjuredPreviouslyDescription: "",
		yourActivityJobDuringInjury: "",
		injuryDuringSport: null,
		typeOfSport: "",
		haveSportClubRegistration: null,
		dateOfSportRegistration: null,
		contactToSportRegistration: "",
		isDeadDueInjury: null,
		isInjuryOccuriedDueJob: null,
		isInjuryDueCarAccident: null,
		involvedAccidentTypeEnum: null,
		isIntoxicatedOrInfluencedOfAlcohol: null,
		policeAddress: "",
		isContactedToPolice: null,
		isWorkInjury: null,
		isInvalidClientOrChildBeforeInjury: null,
		invalidClientOrChildBeforeInjuryFrom: null,
		invalidClientOrChildBeforeDiagnose: null,
		needAccountElapsedExternalWaitingTime: null,
		dateOfFirstTreatment: null,
		timeOfFirstTreatment: "",
		firstDoctorOrAmbulanceName: "",
		continuedTreatmentDoctorOrAmbulanceName: "",
		personalDoctorOrAmbulanceName: "",
		notaryNameAndAddressHandleEstate: "",
		injuryDescriptionModel: [],
		typeOfInjuriesForSeriouseDieses: null,
		otherDiagnoseText: "",
	},
};

type NestedFields = {
	payload: {
		sectionName?: "employeeModel" | "personThroutful" | "enterprenuerModel";
		field: string;
		value: string | boolean | null;
	};
	type: string;
};

const ClaimSlice = createSlice({
	name: "claim",
	initialState,
	reducers: {
		setClientInfo: (state, action) => {
			const {
				personalId,
				contractId = "",
				claimForPersonId,
			} = action.payload;

			return {
				...state,
				clientIdentification: {
					personalId: personalId,
					contractId: contractId,
				},
				claimForPersonId: claimForPersonId,
			};
		},
		setSelectedTypeClaims: (state, action) => {
			return {
				...state,
				selectedTypeOfClaims: action.payload,
			};
		},
		updateFirstStepGeneralFields: (state, action) => {
			const { field, value } = action.payload;
			return {
				...state,
				injuryDescriptionModelBase: {
					...state.injuryDescriptionModelBase,
					[field]: value,
				},
			};
		},
		updateFirstStepDynamicFields: (state, action) => {
			const { field, value, typeOfClaimEnum } = action.payload;
			return {
				...state,
				injuryDescriptionModelBase: {
					...state.injuryDescriptionModelBase,
					injuryDescriptionModel:
						state.injuryDescriptionModelBase.injuryDescriptionModel.map(
							(injuryDescription) =>
								injuryDescription.typeOfClaimEnum === typeOfClaimEnum
									? {
											...injuryDescription,
											[field]: value,
									  }
									: injuryDescription
						),
				},
			};
		},
		updateSecondStepGeneralsNestedFields: (state, action: NestedFields) => {
			const { sectionName, field, value } = action.payload;

			if (!sectionName) {
				return {
					...state,
					personModel: {
						...state.personModel,
						[field]: value,
					},
				};
			}

			return {
				...state,
				personModel: {
					...state.personModel,
					[sectionName]: {
						...state.personModel[sectionName],
						[field]: value,
					},
				},
			};
		},
		updateThirdStepGeneralFields: (state, action) => {
			const { field, value } = action.payload;

			return {
				...state,
				paymentModel: {
					...state.paymentModel,
					[field]: value,
				},
			};
		},
		updateFourthStepFields: (state, action) => {
			const { field, value } = action.payload;

			return {
				...state,
				documentsUploadModel: {
					...state.documentsUploadModel,
					[field]: value,
				},
			};
		},
		updateFifthStepAgentFields: (state, action) => {
			const { field, value } = action.payload;

			return {
				...state,
				reportModel: {
					...state.reportModel,
					agentModel: {
						...state.reportModel.agentModel,
						[field]: value,
					},
				},
			};
		},
		updateFifthStepFields: (state, action) => {
			const { field, value } = action.payload;
			return {
				...state,
				reportModel: {
					...state.reportModel,
					[field]: value,
				},
			};
		},
		updateFifthStepFinishFields: (state, action) => {
			const { value }: { value: finishMethodsType } = action.payload;

			return {
				...state,
				reportModel: {
					...state.reportModel,
					finishedClaimByMobileIdentification:
						value === "mobileIdentification",
					finishedClaimBySendDocumentOnline:
						value === "senddocumentonline",
					finishedClaimBy2DSignature: value === "signature2d",
				},
			};
		},
		resetFiftStepFinishFields: (state) => {
			return {
				...state,
				reportModel: {
					...state.reportModel,
					finishedClaimByMobileIdentification: false,
					finishedClaimBySendDocumentOnline: false,
					finishedClaimBy2DSignature: false,
				},
			};
		},
		resetClaimModel: (state) => {
			return {
				...initialState,
				clientIdentification: {
					personalId: state.clientIdentification.personalId,
					contractId: state.clientIdentification.contractId,
				},
				claimForPersonId: state.claimForPersonId,
			};
		},
		setClaimModel: (state, action) => {
			return {
				...action.payload,
			};
		},

		setUploadedFiles: (state, action) => {
			const { typeOfClaimEnum, files } = action.payload;

			const uploadedFileIndex = state.reportModel.uploadedFiles.findIndex(
				(uploadedFile) => uploadedFile.typeOfClaimEnum === typeOfClaimEnum
			);

			if (uploadedFileIndex === -1) {
				const newUploadedFile = {
					typeOfClaimEnum,
					files: [...files],
				};

				return {
					...state,
					reportModel: {
						...state.reportModel,
						uploadedFiles: [
							...state.reportModel.uploadedFiles,
							newUploadedFile,
						],
					},
				};
			}

			const updatedUploadedFiles = state.reportModel.uploadedFiles.map(
				(uploadedFile) => {
					if (uploadedFile.typeOfClaimEnum === typeOfClaimEnum) {
						return {
							typeOfClaimEnum,
							files: [...files],
						};
					}

					return uploadedFile;
				}
			);

			return {
				...state,
				reportModel: {
					...state.reportModel,
					uploadedFiles: updatedUploadedFiles,
				},
			};
		},
	},
});

export const {
	setClientInfo,
	setSelectedTypeClaims,
	updateFirstStepGeneralFields,
	updateFirstStepDynamicFields,
	updateSecondStepGeneralsNestedFields,
	updateThirdStepGeneralFields,
	updateFourthStepFields,
	updateFifthStepAgentFields,
	updateFifthStepFinishFields,
	resetFiftStepFinishFields,
	updateFifthStepFields,
	resetClaimModel,
	setClaimModel,
	setUploadedFiles,
} = ClaimSlice.actions;
export default ClaimSlice.reducer;
